import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Link } from "gatsby";
import { Button, Grid } from "@theme-ui/components";
export const _frontmatter = {
  "title": "Enrolment"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "enrolment"
    }}>{`Enrolment`}</h1>
    <h3 {...{
      "id": "welcome-to-de-la-salle-as-a-non-zoned-catholic-college-we-accept-enrolments-from-all-over-the-melbourne-metropolitan-area-and-we-are-proud-of-our-ability-to-educate-young-men-across-a-wide-selection-of-interest-areas-our-academic-results-are-excellent-and-we-have-a-highly-successful-vcal-program"
    }}>{`Welcome to De La Salle, as a non-zoned Catholic College, we accept enrolments from all over the Melbourne Metropolitan area and we are proud of our ability to educate young men across a wide selection of interest areas. Our academic results are excellent and we have a highly successful VCAL program.`}</h3>
    <h3 {...{
      "id": "due-to-covid-19-our-college-tours-are-currently-being-conducted-virtually-a-virtual-tour-allows-all-members-of-the-family-to-experience-what-makes-de-la-salle-so-special-from-the-comfort-of-your-own-home"
    }}>{`Due to COVID-19, our college tours are currently being conducted `}<a parentName="h3" {...{
        "href": "https://www.delasalle.vic.edu.au/virtualtour/"
      }}>{`virtually`}</a>{`. A virtual tour allows all members of the family to experience what makes De La Salle so special from the comfort of your own home.`}</h3>
    <h2 {...{
      "id": "attend-our-year-7-information-evening-730-pm--wed-5-august"
    }}>{`Attend our Year 7 information evening 7.30 pm – Wed 5 August`}</h2>
    <p>{`You are invited to take part in our Year 7 information evening (followed by a Q&A session) with our Year 7 leadership team. During this discussion evening you will discover how we encourage our boys to learn and lead in the very best way possible - with wide curriculum choice, excellent facilities and dedicated teachers. We will also pay special attention to transition and provide information about how we will work with families to make this as smooth as possible (especially given most students commencing year 7 in 2021 will have had some unique challenges to overcome in 2020).`}</p>
    <h4 {...{
      "id": "other-qa-sessions"
    }}>{`Other Q&A sessions`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": "right"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Wednesday 5 August 7:30pm`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}><Button variant="secondary" href="https://www.trybooking.com/BKPFR" as={Link} mdxType="Button">{`Book Here`}</Button></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Thursday 6 August 11:30am`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}><Button variant="secondary" href="https://www.trybooking.com/book/event?eid=641158" as={Link} mdxType="Button">{`Book Here`}</Button></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Thursday 13 August 11:30am`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}><Button variant="secondary" href="https://www.trybooking.com/book/event?eid=641169" as={Link} mdxType="Button">{`Book Here`}</Button></td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "read-more-about-de-la-salle-in-our-prospectus"
    }}>{`Read more about De La Salle in our Prospectus`}</h4>
    <p>{`Enjoy reading our Prospectus as it takes you through what it means to learn, live and lead at De La Salle College. You can download a copy of the prospectus `}<a parentName="p" {...{
        "href": "/2016_Prospectus.web.pdf"
      }}>{`here`}</a>{`.`}</p>
    <div {...{
      "className": "gatsby-resp-iframe-wrapper",
      "style": {
        "paddingBottom": "70.75%",
        "position": "relative",
        "height": "0",
        "overflow": "hidden"
      }
    }}>{` `}<iframe parentName="div" {...{
        "src": "//e.issuu.com/embed.html#3624934/39133365",
        "frameBorder": "0",
        "allowFullScreen": true,
        "style": {
          "position": "absolute",
          "top": "0",
          "left": "0",
          "width": "100%",
          "height": "100%"
        }
      }}></iframe>{` `}</div>
    <p>{`Our key enrolment years are grade 5 and year 7, however please if you have a son looking to change schools please our registrar as enrolments can be made mid-year and across all year levels if vacancies exist.`}</p>
    <h4 {...{
      "id": "how-do-i-enrol-my-son"
    }}>{`How do I Enrol my Son?`}</h4>
    <ul>
      <li parentName="ul">{`Download our `}<a parentName="li" {...{
          "href": "http://www.delasalle.vic.edu.au/wp-content/uploads/2020/06/CURRENT-2020-APP-FORM-WEB-COMBINED.pdf"
        }}>{`application form`}</a></li>
      <li parentName="ul">{`Speak with our Registrar on 9508 2100`}</li>
    </ul>
    <h4 {...{
      "id": "when-should-i-enrol-my-son"
    }}>{`When Should I Enrol my Son?`}</h4>
    <p>{`At De La Salle we accept applications from birth and we suggest families apply to enrol their son as early as possible to secure a place from primary. Once enrolled, your family are part of the De La Salle community and you will receive regular updates from the school. The year before your son is scheduled to commence with the college you will be asked to confirm your commitment.`}</p>
    <h4 {...{
      "id": "primary-applications"
    }}>{`Primary Applications`}</h4>
    <p>{`Primary applications are considered in Term 2 of the year prior to the proposed commencement. Please contact our Registrar for further information.`}</p>
    <h4 {...{
      "id": "fees"
    }}>{`Fees`}</h4>
    <p>{`If you are interested in how we structure our fees we have our `}<a parentName="p" {...{
        "href": "https://www.delasalle.vic.edu.au/enrolment/fee-schedule/"
      }}>{`fee schedule here`}</a>{` for your reference. The College’s fees are all-inclusive.`}</p>
    <h4 {...{
      "id": "uniforms"
    }}>{`Uniforms`}</h4>
    <p>{`Our `}<a parentName="p" {...{
        "href": "https://www.delasalle.vic.edu.au/enrolment/uniforms/"
      }}>{`uniforms`}</a>{`, characterised by the iconic blue and gold blazers, are worn with pride by all of our students, from Year 5 to Year 12.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      